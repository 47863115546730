'use client';

import Modal from '@/components/core/Modal/Modal';
import { Popup } from '@/components/core/Popup/Popup';
import ExpandableText from '@/components/ExpandableText/ExpandableText';
import { NEXT_PUBLIC_SITE_URL } from '@/config/env';
import { useBreakpoints } from '@/hooks/useBreakpoints';
import clsx from 'clsx';
import { useTranslations } from 'next-intl';
import React, { useEffect, useState } from 'react';
import { FiShare2 } from 'react-icons/fi';
import styles from './ShareButton.module.scss';

// Define the props types for ShareButton
interface ShareButtonProps {
    ButtonComponent?: any;
    buttonProps?: any;
    showTick?: boolean;
    link: string;
}

const ShareButton: React.FC<ShareButtonProps> = ({ ButtonComponent, buttonProps = {}, showTick = true, link }) => {
    const [show, setShow] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalIsCopied, setShowModalIsCopied] = useState(false);
    const t = useTranslations('shareButton');
    const { isXSmall, isMobile, isTablet } = useBreakpoints();

    const handleClick = () => {
        setShow(true);
        navigator.clipboard.writeText(`${NEXT_PUBLIC_SITE_URL}${link}`);
    };

    const handleClickModal = () => {
        setShowModalIsCopied(true);
        navigator.clipboard.writeText(`${NEXT_PUBLIC_SITE_URL}${link}`);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setShowModalIsCopied(false);
    };

    // Default content using FiShare2 when no ButtonComponent is provided
    const DefaultIcon = () => (
        <FiShare2 style={{ cursor: 'pointer', transform: 'translateY(5px)' }} onClick={handleClick} />
    );
    useEffect(() => {
        if (show && (isXSmall || isMobile || isTablet)) {
            setShowModal(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    return (
        <div className={styles.root}>
            {ButtonComponent ? <ButtonComponent {...buttonProps} onClick={handleClick} /> : <DefaultIcon />}
            <Modal
                view="info"
                overlayClass={styles.modalWrap}
                className={styles.modal}
                showModal={showModal}
                hideModal={handleCloseModal}
            >
                <div className={styles.modalInner}>
                    <div className={styles.label}>{t('copyLink')}</div>
                    <div className={styles.inputStyle}>
                        <div className={styles.expandableClass}>
                            <ExpandableText text={`${NEXT_PUBLIC_SITE_URL}${link}`} />
                        </div>
                        <div
                            className={clsx(styles.copyButton, { [styles.active]: showModalIsCopied })}
                            onClick={handleClickModal}
                        >
                            {showModalIsCopied ? t('copied') : t('copy')}
                        </div>
                    </div>
                </div>
            </Modal>
            <div className={styles.popupWrap}>
                <Popup
                    className={clsx(styles.popup, { [styles.hide]: !show })}
                    show={show}
                    closePopup={setShow}
                    showTick={showTick}
                />
            </div>
        </div>
    );
};

export default ShareButton;
