import clsx from 'clsx';
import React, { useCallback, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import { Icon } from '../Icon/Icon';

import styles from './Modal.module.scss';

type BaseModalProps = {
    className?: string;
    showModal: boolean;
    hideModal?: () => void;
    overlayClass?: string; //todo: check this
};

type FullscreenModalProps = {
    view: 'fullscreen';
    data?: {};
};

type InfoModalProps = {
    view: 'info';
    data?: {};
};

type ModalPropsNew = BaseModalProps & (FullscreenModalProps | InfoModalProps);

const Modal: React.FC<React.PropsWithChildren<ModalPropsNew>> = ({
    className,
    showModal,
    hideModal,
    overlayClass,
    view,
    children,
    data,
}) => {
    const htmlElement = useRef(document.getElementsByTagName('html')[0]);

    useEffect(() => {
        if (htmlElement.current && showModal) {
            htmlElement.current.setAttribute('style', 'overflow:hidden');
            window.addEventListener('keydown', handleEscape);
        }

        return () => {
            if (showModal) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                htmlElement.current?.removeAttribute('style');
            }
            window.removeEventListener('keydown', handleEscape);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModal]);

    const handleEscape = useCallback((event: any) => {
        if (event.keyCode === 27) hideModal?.();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return showModal
        ? createPortal(
              <div className={styles.animatedDiv}>
                  <div className={clsx(styles.overlay, overlayClass)}>
                      <div className={clsx(styles.box, styles[view], className)}>
                          {view === 'info' && (
                              <>
                                  {hideModal && (
                                      <Icon name={'close'} className={clsx(styles.btnClose)} onClick={hideModal} />
                                  )}
                                  {children}
                              </>
                          )}

                          {view === 'fullscreen' && children}
                      </div>
                      {view === 'fullscreen' && hideModal && (
                          <Icon name={'close'} className={clsx(styles.btnClose)} onClick={hideModal} />
                      )}
                  </div>
              </div>,
              document.body,
          )
        : null;
};

export default Modal;
