import { useEffect, useRef, useState } from 'react';

import styles from './ExpandableText.module.scss';

const ExpandableText = ({
    className = '',
    text = '',
    maxLines = 3,
    lineHeight = 1.5,
    containerWidth = '100%',
    button = {
        className: styles.button,
        text: 'Show More',
    },
    hideButton = false,
}) => {
    const [showFullText, setShowFullText] = useState(false);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const textRef = useRef(null);

    useEffect(() => {
        const checkOverflow = () => {
            if (textRef.current) {
                const { scrollHeight, clientHeight } = textRef.current;
                setIsOverflowing(scrollHeight > clientHeight);
            }
        };

        checkOverflow();

        window.addEventListener('resize', checkOverflow);

        return () => {
            window.removeEventListener('resize', checkOverflow);
        };
    }, [text, showFullText]);

    const handleShowMore = (e: any) => {
        e.preventDefault();
        setShowFullText(true);
    };

    return (
        <div className={className} style={{ width: containerWidth }}>
            <div
                ref={textRef}
                className={styles.text}
                style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: showFullText ? 'unset' : maxLines,
                    whiteSpace: 'normal',
                    margin: 0,
                }}
            >
                {text}
            </div>
            {!hideButton && isOverflowing && !showFullText && (
                <div onClick={handleShowMore} className={styles.button}>
                    {button.text}
                </div>
            )}
        </div>
    );
};

export default ExpandableText;
