import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import { FaRegPenToSquare } from 'react-icons/fa6';
import { Typography } from '../typography/Typography';
import styles from './Author.module.scss';
import ShareButton from './components/ShareButton';

type AuthorProps = {
    image: string;
    name: string;
    isInfluencer?: boolean;
    isSmallSize?: boolean;
    shareCode?: string;
    isMenuBar?: boolean;
};

const Author: React.FC<AuthorProps> = ({
    image,
    name,
    isInfluencer = false,
    isSmallSize = false,
    isMenuBar = false,
    shareCode,
}) => {
    return (
        <div className={styles.container}>
            <span className={styles.info}>
                <div className={clsx(styles.imgcontainer, isSmallSize && styles.smallImgcontainer)}>
                    <Image src={image} fill className={styles.image} alt={'portrait'} />
                </div>
                {isMenuBar ? (
                    <Typography.PStrong>{name}</Typography.PStrong>
                ) : (
                    <Typography.H1 as="h4">{name}</Typography.H1>
                )}
            </span>
            <div className={styles.icons}>
                {shareCode && <ShareButton link={`/share/profile/${shareCode}`} />}
                {isInfluencer && (
                    <Link className={styles.link} href={'/create-profile'} aria-label={'edit profile'}>
                        <FaRegPenToSquare />
                    </Link>
                )}
            </div>
        </div>
    );
};
export default Author;
