import { dmSans, playfairDisplay, workSans } from '@/app/fonts';
import clsx from 'clsx';
import Link from 'next/link';
import { ReactNode } from 'react';
import styles from './Typography.module.scss';

type TypoProps = {
    children?: string | number | ReactNode;
    href?: string;
    classname?: string;
    as?: string;
    target?: '_blank' | undefined
};

const H1: React.FC<TypoProps> = ({ children, classname, as }) => {
    return (
        <h1
            className={clsx(
                styles.h1,
                classname,
                as === 'h4' && styles.h4,
                as === 'h4' && dmSans.className,
                !as && playfairDisplay.className,
            )}
        >
            {children}
        </h1>
    );
};

const H2: React.FC<TypoProps> = ({ children, classname, as }) => {
    return <h2 className={clsx(styles.h2, classname, as === 'h4' ? workSans.className : playfairDisplay.className, as === 'h3' && styles.h3, as === 'h4' && styles.h4, as === 'h4' && dmSans.className)}>{children}</h2>;
};

const H3: React.FC<TypoProps> = ({ children, classname, as }) => {
    return <h3 className={clsx(styles.h3, classname, playfairDisplay.className, as === 'h4' && styles.h4, as === 'h4' && workSans.className)}>{children}</h3>;
};

const H4: React.FC<TypoProps> = ({ children, classname }) => {
    return <h4 className={clsx(styles.h4, classname)}>{children}</h4>;
};

const P: React.FC<TypoProps> = ({ children, classname }) => {
    return <p className={clsx(styles.p, classname)}>{children}</p>;
};

const PStrong: React.FC<TypoProps> = ({ children, classname }) => {
    return <p className={clsx(styles.p_strong, classname)}>{children}</p>;
};

const PLink: React.FC<TypoProps> = ({ children, classname, href }) => {
    return (
        <a className={clsx(styles.p_link, classname)} href={href}>
            {children}
        </a>
    );
};

const Small: React.FC<TypoProps> = ({ children, classname }) => {
    return <small className={clsx(styles.small, classname)}>{children}</small>;
};

const SmallStrong: React.FC<TypoProps> = ({ children, classname }) => {
    return <small className={clsx(styles.small_strong, classname)}>{children}</small>;
};

const SmallLink: React.FC<TypoProps> = ({ children, classname, href, target }) => {
    return href ? (
        <Link href={href} className={clsx(styles.small_link, classname)} target={target}>
            {children}
        </Link>
    ) : (
        <small className={clsx(styles.small_link, classname)}>{children}</small>
    );
};

const Button: React.FC<TypoProps> = ({ children, classname }) => {
    return <span className={clsx(styles.button, classname)}>{children}</span>;
};

const Tag: React.FC<TypoProps> = ({ children, classname }) => {
    return <span className={clsx(styles.tag, classname)}>{children}</span>;
};
const Form: React.FC<TypoProps> = ({ children, classname }) => {
    return <span className={clsx(styles.form, classname)}>{children}</span>;
};

export const Typography = {
    H1,
    H2,
    H3,
    H4,
    P,
    PStrong,
    PLink,
    Small,
    SmallStrong,
    SmallLink,
    Button,
    Tag,
    Form,
};
